<template>
  <v-container
    fluid
    class="h-100 pa-0"
  >
    <div class="header-details">
      <h1>BYU Software Provisioning</h1>
      <h2 class="my-4">
        Build and manage applications to provision software<br> for BYU students, faculty and staff.
      </h2>
      <v-btn 
        color="accent"
        class="my-4 px-10 start-button"
        x-large
        @click="loginUser"
      >
        Get Started
      </v-btn>
    </div>
    <v-container>
      <v-row class="info-sheet">
        <v-col
          cols="12"
          lg="4"
          md="6"
          sm="12"
          v-for="summary in summaries"
          :key="summary.title"
          class="px-4"
        >
          <v-row class="mx-4">
            <v-col 
              class="ma-0 pa-0"
              cols="2"
            >
              <v-icon
                x-large
                color="accent"
                class="pt-1"
              >
                {{ summary.icon }}
              </v-icon>
            </v-col> 
            <v-col class="ma-0 pa-0">
              <h3 class="pb-3">
                {{ summary.title }}
              </h3>
              <p>{{ summary.details }}</p>
            </v-col>
          </v-row>
        </v-col> 
      </v-row>
    </v-container>
    <!-- <div class="additional-info mt-6">
      <p>Origionally built over 5 years ago, BYU Provisioning is a custom build provisioning system to meet the software provisioning needs of BYU. Notable applications include BYU's access control systems, Microsoft Licensing, Zoom Access, and many others. BYU Provisioning allows application administrators to scale their application licensing through GRO groups as a quick and efficient solution for managing software provisioning.</p>
    </div> -->
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "Landing",
  data () {
    return {
      summaries: [
        {
          icon: "mdi-shield-account",
          title: "MANAGE ACCESS",
          details: "Use the BYU Provisioning system to seemlessly assign software access to members of the campus community",
        },
        {
          icon: "mdi-code-tags",
          title: "BUILD APPLICATIONS",
          details: "Use Provisioning's flexible API to integrate software provisioning into your application.",
        },
        {
          icon: "mdi-list-status",
          title: "MONITOR LOGS",
          details: "Keep track of users experiencing issues accessing your application. Debug and monitor your application",
        }
      ]
    }
  },
  methods: {
    loginUser() {
      Auth.federatedSignIn({provider: 'Azure'})
    },
  }
}
</script>

<style scoped>

.header-details {
  background-image: url('../../assets/campus-bg.jpg');
  color: white;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 8vh;

  /* Full height */
  height: 50%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-details h2 {
  font-weight: lighter;
  font-size: 22px;
}

.info-sheet {
  padding-top: 2em;
}

.additional-info {
  background-color: #E8EAEA;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 3em;
  padding-bottom: 3em;
}

.start-button {
  text-transform: none;
  font-weight: bolder;
}

h3 {
  font-size: 24px;
}

p {
  font-size: 16px
}
</style>